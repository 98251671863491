import React, { useContext, useEffect, useState } from 'react';
import ODAS from '@/utils/api/odas';
import { useRouter } from 'next/router';
import useLocalStorageSession from '@/hooks/useLocalStorageSession';
import { AccountContext } from '@/context/account.context';
import find from 'lodash/find';
import trim from 'lodash/trim';
import { getUrlEncodedPath } from '@/utils/utils';

const BASE_PATH = '/saml/sp/init/';

/**
 * Provide logic to components that need to detect a login or signup attempt from an account
 * that should be redirected into the SAML SSO flow instead.
 */

const useSaml = () => {
  const { deleteSessionValue, sessionObject, setSessionValue } = useLocalStorageSession();
  //const [samlPartner, setSamlPartner] = useState(false);
  const [lastSubmittedValue, setlastSubmittedValue] = useState('');
  const router = useRouter();
  const { getSamlConfig, samlConfig, samlPartner, setSamlPartner } = useContext(AccountContext);

  useEffect(() => {
    if (samlConfig === false) {
      getSamlConfig();
      return;
    }
    let provider = false;
    if (router.query?.provider) {
      setSessionValue('provider', router.query?.provider);
      provider = router.query?.provider.replace('saml_', '');
    } else if (sessionObject.provider) {
      provider = sessionObject.provider.replace('saml_', '');
    }
    if (provider && samlConfig[provider]) {
      setSamlPartner(samlConfig[provider]);
    }
  }, [samlConfig]);

  const detectSamlPartner = (email) => {
    if (email && samlConfig) {
      let found = find(samlConfig, (partnerConfig) => {
        return partnerConfig.email_regex.test(email);
      });
      return found ? found : false;
    }
    return false;
  };

  const handleBlurOnEmailOrPhone = (event) => {
    let partner = detectSamlPartner(event.target.value);
    if (partner !== false) {
      setSamlPartner(partner);
      setSessionValue('provider', 'saml_' + partner.slug);
    } else {
      setSamlPartner(false);
      deleteSessionValue('provider');
    }
  };

  const handleKeyPressOnEmailOrPhone = (event) => {
    // check if enter key and input has a new value
    if (event?.which == 13 && lastSubmittedValue !== event.target.value) {
      // if so, block submission and detectSamlPartner
      event.preventDefault();
      handleBlurOnEmailOrPhone(event);
      // also set lastSubmittedValue to current value
      setlastSubmittedValue(event.target.value);
    } else if (lastSubmittedValue !== event.target.value) {
      // clear samlPartner
      setSamlPartner(false);
      setSessionValue('provider', 'undefined');
    }
  };

  const handleSAMLRedirect = (event) => {
    if (samlPartner) {
      if (event?.preventDefault) {
        event.preventDefault();
      }
      // redirect to the beginning of the SSO flow
      const returnUrl = router.query?.r || getUrlEncodedPath();
      window.location.href = getSAMLEntryUrl(samlPartner.slug, returnUrl);
      return true;
    } else return false;
  };

  const getSAMLEntryUrl = (slug, returnUrl) => {
    return BASE_PATH + slug + (returnUrl ? '?r=' + returnUrl : '');
  };

  return {
    detectSamlPartner,
    handleBlurOnEmailOrPhone,
    handleKeyPressOnEmailOrPhone,
    handleSAMLRedirect,
    samlConfig,
    samlPartner
  };
};

export default useSaml;
