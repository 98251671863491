import React from 'react';
import classes from './Disclaimer.module.scss';
import t from '@/utils/Language/Modal/LoginModal-translations';

const Disclaimer = () => {
  return (
    <p
      className={classes.privacyPolicy}
      dangerouslySetInnerHTML={{
        __html: t('Signup legal disclaimer', {
          privacy_policy_link: 'https://about.1degree.org/privacy-policy',
          tou_link: 'https://about.1degree.org/terms-of-use'
        })
      }}
    ></p>
  );
};

export default Disclaimer;
