import React, { forwardRef } from 'react';
import classes from './Button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';

// Default styles for Button
const defaultButtonStyles = {
  type: 'secondary',
  shape: 'pill',
  width: 'auto'
};

// Default onClick event
const onClick = () => {};

/**
 * Button UI
 * @param text Button label text (String)
 * @param type (primary | primary-bright | secondary (default) | secondary-white | iconOnly | link)
 * @param shape (pill (default) | rounded | circle | square)
 * @param icon ({ style: 'fas', font: 'heart', position: (left|right), color: #fff })
 * @param state (selected | unselected | disabled)
 * @param disabled ( true | false )
 * @param groupPosition (left | center | right)
 * @param border (true | false (default))
 * @param additionalClasses String with classes from the components using the Button (optional)
 * @param styles Different styles provided (optional)
 *  {
 *    font
 *    hidden
 *    width: null | auto (default) | full | <number> (px)
 *  }
 * @param onButtonClick Button click event
 * @param htmlType String of HTML <button> type (i.e. 'submit', 'button', ...) (optional)
 * @param dataCy Data attribute used for Cypress tests
 * @param badge Number that appears on the corner of the button in a circle (best with 2 digits or less) (optional)
 */
function Button(
  {
    text,
    type,
    shape,
    icon,
    state,
    disabled,
    groupPosition,
    border,
    additionalClasses,
    styles,
    onButtonClick,
    htmlType = 'button',
    dataCy,
    badge = null,
    children
  },
  ref
) {
  styles = Object.assign({}, defaultButtonStyles, styles || {});
  onButtonClick = onButtonClick && !styles?.disabled ? onButtonClick : onClick;
  const buttonClasses = [
    classes.btn,
    classes[type ? type : defaultButtonStyles.type],
    classes[shape || defaultButtonStyles.shape],
    classes[disabled ? 'disabled' : ''],
    classes[state ? state : ''],
    classes[border ? 'border' : ''],
    classes[groupPosition ? `btn-${groupPosition}` : ''],
    additionalClasses
  ].join(' ');
  const buttonIconContainerClasses = [classes['button-icon'], classes[`button-icon-${icon?.position}`], classes[`button-icon-${icon?.color}`]].join(
    ' '
  );

  const textStyles = () => {
    let result = {};
    if (styles?.text) {
      switch (styles?.text) {
        case 'underline':
          result['textDecoration'] = 'underline';
          break;
        case 'spaced':
          result['marginRight'] = '10px';
          break;
      }
    }
    return result;
  };

  const buttonStyles = () => {
    let result = {};
    if (styles) {
      if (styles.font) {
        result.font = styles.font;
      }
      if (styles.hidden) {
        result.display = 'none';
      }
      if (styles.fontSize) {
        result['fontSize'] = styles.fontSize;
      }
      if (styles?.color) {
        switch (styles.color) {
          case 'red':
            // consider refactoring this if we have more use cases for setting color.
            // for example, it'd be better to use the defined colors from _colors.scss
            result.color = '#C73539';
            break;
          default:
            result.color = styles.color;
            break;
        }
      }
      if (styles?.width) {
        switch (styles?.width) {
          case 'full':
            result.width = '100%';
            break;
          case 'half':
            result.width = '48%';
            break;
          case 'auto':
            result.width = 'auto';
            break;
          case '':
            result.width = 'inherit';
          default:
            result.width = `${styles?.width}px`;
            break;
        }
      }
    }
    return result;
  };

  const buttonTextWithIcon = (text, icon) => {
    let iconEle;
    if (icon && icon?.font) {
      iconEle = (
        <span className={[buttonIconContainerClasses]}>
          <FontAwesomeIcon icon={[`${icon?.style}`, `${icon?.font}`]} />
          {icon?.position === 'left' && <span style={textStyles()}>{text}</span> && <></>}
        </span>
      );
    }
    const showContent = !isEmpty(text) || children;
    return (
      <>
        {iconEle && (icon?.position === 'left' || icon?.position == undefined) ? (
          <>
            {iconEle}
            {showContent && <>&nbsp;</>}
          </>
        ) : (
          ''
        )}
        {showContent && (
          <>
            <span style={textStyles()} className={`${classes.buttonText || ''}`}>
              {text || children}
            </span>
          </>
        )}
        {iconEle && icon?.position === 'right' ? (
          <>
            {!isEmpty(text) && <>&nbsp;</>}
            {iconEle}
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <>
      <button
        data-cy={dataCy}
        ref={ref}
        style={buttonStyles()}
        disabled={state == 'disabled' || disabled}
        className={[`${buttonClasses}`]}
        onClick={onButtonClick}
        type={htmlType}
      >
        {badge !== null && <span className={classes.badge}>{badge}</span>}
        {buttonTextWithIcon(text, icon)}
      </button>
    </>
  );
}

export default forwardRef(Button);
