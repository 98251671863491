import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Language from '@/utils/Language';
import Form from 'react-bootstrap/Form';
import Button from '@/components/Button/Button';
import classes from './LoginPage.module.scss';
import t from '@/utils/Language/Modal/LoginModal-translations';
import useAccount from '@/hooks/useAccount';
import useSaml from '@/hooks/useSaml';
import { regexFilledField } from '@/utils/utils';
import ReCAPTCHA from 'react-google-recaptcha';
import { modalContext } from '@/context/modal.context';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import Disclaimer from '@/components/Disclaimer/Disclaimer';

const LoginForm = ({ context, onLoginComplete, signupModalProps, startingEmailOrPhoneInputValue, ...props }) => {
  const {
    register,
    unregister,
    watch,
    formState: { errors }
  } = useForm();
  const { login } = useAccount();
  const { samlPartner, handleBlurOnEmailOrPhone, handleKeyPressOnEmailOrPhone, handleSAMLRedirect, samlConfig } = useSaml();
  const { enqueueModal } = useContext(modalContext);
  const [loginAttemptCount, setLoginAttemptCount] = useState(0);
  const [procesing, setProcessing] = useState(false);
  const maxLoginAttempts = 3;
  const [captchaState, setCaptchaState] = useState({ value: '' });
  const modal = useContext(modalContext);
  const router = useRouter();

  const trackLoginAttempt = (e) => {
    e.preventDefault();
    setLoginAttemptCount((prevState) => prevState + 1);
    setProcessing(true);
    if (handleSAMLRedirect()) {
      return;
    }
    login(e)
      .then((data) => {
        setProcessing(false);
        onLoginComplete(data);
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const onChangeCaptcha = (value) => {
    setCaptchaState({ value });
    if (value === null) setCaptchaState({ value: '' });
  };

  const openForgotPasswordModal = () => {
    modal.enqueueModal(
      {
        name: 'ForgotPasswordModal',
        reason: 'forgot_password'
      },
      { forceOpen: true }
    );
  };

  const openSignup = (e) => {
    e.preventDefault();
    if (context == 'modal') {
      enqueueModal(
        {
          name: 'SignupModal',
          startingEmailOrPhoneInputValue,
          ...signupModalProps,
          backdrop: typeof props.backdrop !== 'undefined' ? props.backdrop : true
        },
        { forceOpen: true }
      );
    } else {
      router.push('/signup');
    }
  };

  return (
    <>
      {samlPartner === false && (
        <>
          <p className={classes.bodyTitle}>
            {t("Don't have an account?")}{' '}
            <a href="#" onClick={openSignup}>
              {t('Sign up.')}
            </a>
          </p>
          <p className={classes.divider}>
            <span className={[`${classes.dividerLine} ${classes.dividerLineL}`]}></span>
            <span className={classes.dividerText}>{t('or')}</span>
            <span className={[`${classes.dividerLine} ${classes.dividerLineR}`]}></span>
          </p>
        </>
      )}
      {samlPartner !== false && <p dangerouslySetInnerHTML={{ __html: t(samlPartner.login_prompt_key) }} />}
      <Form onSubmit={trackLoginAttempt}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            data-cy="modal-login-input-email"
            type="text"
            placeholder={t('Email or cell phone')}
            defaultValue={startingEmailOrPhoneInputValue}
            onKeyPress={handleKeyPressOnEmailOrPhone}
            {...register('email', { onBlur: handleBlurOnEmailOrPhone, required: true })}
            pattern={regexFilledField}
          />
          <label>{t('Email or cell phone')}</label>
        </Form.Group>
        {samlPartner === false && (
          <Form.Group className="mb-0" controlId="formBasicPassword">
            <Form.Control
              data-cy="modal-login-input-password"
              className={`${classes.loginField} ${classes.middleField}`}
              type="password"
              placeholder={t('Password')}
              {...register('password', { required: true })}
              pattern={regexFilledField}
            />
            <label>{t('Password')}</label>
          </Form.Group>
        )}
        <p>
          {errors.email && <span>{t('Email is required')}</span>}
          {errors.password && <span>{t('Password is required')}</span>}
        </p>
        {samlPartner === false && (
          <p className={classes.forgotPassword}>
            <a className={classes.link} onClick={openForgotPasswordModal}>
              {t('Forgot password?')}
            </a>
          </p>
        )}
        {loginAttemptCount >= maxLoginAttempts && (
          <div>
            <p>{t('Too many failed login attempts. Please confirm CAPTCHA and then try again.')}</p>
            <ReCAPTCHA
              className={classes.captcha}
              hl={Language.getCurrentLanguage()}
              sitekey={process.env.RECAPTCHA_PUBLIC_KEY}
              onChange={onChangeCaptcha}
            />
          </div>
        )}
        <Button
          type="primary"
          disabled={(loginAttemptCount >= maxLoginAttempts && !captchaState.value) || samlConfig === false || procesing}
          dataCy={'modal-login-btn-login'}
          styles={{ width: 'full' }}
          text={samlPartner !== false ? t(samlPartner.login_button_key) : t('Log in')}
          htmlType="submit"
        />
      </Form>
      {samlPartner === false && <Disclaimer></Disclaimer>}
    </>
  );
};

LoginForm.propTypes = {
  context: PropTypes.string,
  onLoginComplete: PropTypes.func,
  signupModalProps: PropTypes.object,
  startingEmailOrPhoneInputValue: PropTypes.string
};

LoginForm.defaultProps = {
  onLoginComplete: () => {},
  signupModalProps: {}
};

export default LoginForm;
